.react-datepicker-wrapper {
    width: 100%;
    border-radius: 2px;
}

/* Increase the size of the datepicker popup */
.react-datepicker {
    font-size: 1.5rem; /* Adjust the font size as needed */
}

.react-datepicker__header {
    padding-top: 1.5rem; /* Adjust padding to match the new font size */
}

.react-datepicker__month-container {
    width: 100%; /* Ensure the month container takes the full width */
    height: 100%;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
    line-height: 3rem; /* Adjust line height to match the new font size */
    font-size: 1.5rem; /* Ensure the font size is consistent */
    padding-left: 2rem;
    padding-right: 2rem;
}

.react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.react-datepicker__day, .react-datepicker__day-name {
    width: 100% !important;
}

.react-datepicker__day {
    height: 3rem;
    align-content: center;
}

.react-datepicker__current-month
{
    margin-bottom: 1rem !important;
    font-size: 1.4rem !important; /* Adjust header font size as needed */
}
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-size: 1.4rem !important; /* Adjust header font size as needed */
}

.react-datepicker-wrapper input[type=text] {
    border: .1rem solid var(--input-border-color);
}

.has-error > .react-datepicker-wrapper input[type=text] {
    border-color: var(--input-error-border-color);
}

.react-datepicker-wrapper input[type=text]:hover {
    visibility: visible !important;
    opacity: 1 !important;
}

.react-datepicker__navigation {
    margin-top: 0.7rem !important;
}

.react-datepicker__month {
    margin: 0 !important; /* Adjust margin to increase the size of the calendar */
    padding: 1.5rem; /* Add padding to increase the size of the month container */
    width: 100%; /* Ensure the month container takes the full width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    height: 100%;
}

.react-datepicker__week {
    display: flex; /* Ensure the week container is displayed as a flex container */
    justify-content: space-around; /* Align the days evenly within the week container */
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #216ba5; /* Adjust the background color of selected day */
    color: #fff; /* Adjust the color of selected day */
}

.react-datepicker__custom-calendar-icon {
    position: absolute;
    right: 0.8rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    fill: #6b6b6b;
}